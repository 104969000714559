<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'Front',
  data() {
    return {

    }
  },
  created() {},
  methods: {

  }
}
</script>

<style>

</style>
